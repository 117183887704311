:root{--discount-app-components-version-number: "3.0.1"}

.DiscountAppComponents-SelectedItemsList-Item{padding:var(--p-space-400) 0;display:flex;align-items:center;justify-content:space-between}.DiscountAppComponents-SelectedItemsList-Item:not(:last-child){border-bottom:var(--p-border-width-025) solid var(--p-color-border-secondary)}.DiscountAppComponents-SelectedItemsList-Item__Content{flex:1 1 auto;padding:var(--p-space-100);max-width:calc(100% - 1.25rem);overflow:hidden}.DiscountAppComponents-SelectedItemsList-Item__Actions{flex:0 0 1.25rem;height:1.25rem}

.DiscountAppComponents-SelectedItemsList{padding:0;list-style:none;margin:0}

.DiscountAppComponents-CountriesAndRatesCard__ShippingRatesTextField{max-width:9.375rem}.DiscountAppComponents-CountriesAndRatesCard__CountryModalActivator{margin-top:var(--p-space-200)}

.DiscountAppComponents-CustomerEligibilityCard__CustomerItem{display:flex;gap:var(--p-space-300);align-items:center}.DiscountAppComponents-CustomerEligibilityCard__Email{overflow:hidden;text-overflow:ellipsis;color:var(--p-color-text-secondary)}.DiscountAppComponents-CustomerEligibilityCard__SelectedItemsActivator{margin-top:var(--p-space-200)}

.DiscountAppComponents-MinimumRequirementsCard__TextField{max-width:9.375rem}

.DiscountAppComponents-UsageLimitsCard-RecurringPayment__RecurringPaymentTextField{max-width:9.375rem}

.DiscountAppComponents-UsageLimitsCard__TotalUsageLimitTextField{max-width:9.375rem}

.DiscountAppComponents-AppliesTo__SelectedItemsActivator{margin-top:var(--p-space-200)}

.DiscountAppComponents-ValueCard__Error{margin-top:var(--p-space-100)}